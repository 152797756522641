<template>
  <section class="bg-white p-6 rounded-[8px] flex flex-col gap-[20px]">
    <span class="font-semibold text-[20px] text-[#333] leading-[30px]">Partner</span>
    <div
      style="flex-wrap: wrap-reverse;"
      class="flex gap-[24px] lg:!flex-nowrap text-[#333333]"
    >
      <div class="w-full p-[16px] rounded-[12px] border-[1px] border-[#E2E2E2] grow">
        <div class="flex justify-between items-center mb-[16px]">
          <span class="font-semibold text-[16px]">Kategori Partner</span>
          <div class="ms-auto selection">
            <VSelect
              v-model="filterCategory"
              :reduce="e => e.value"
              :options="optionsFilter"
              label="text"
              :clearable="false"
              :disabled="loading.category"
              class="font-semibold"
              style="width: 100px;"
              @input="getPartnerCategory()"
            />
          </div>
        </div>
        <b-overlay
          :show="loading.category"
          spinner-variant="primary"
        >
          <div class="flex flex-wrap gap-[16px] [&>*]:grow [&>*]:basis-[295px]">
            <CardData
              :table-head="'Gender'"
              :data="dataGender"
            />
            <CardData
              :table-head="'Tipe Pebisnis'"
              :data="dataBusinessType"
              class-name="h-[167px]"
            />
            <CardData
              :table-head="'Kategori Bisnis'"
              :data="dataBusinessCategory"
              class-name="h-[251px]"
            />
            <CardData
              :table-head="'Kota'"
              :data="dataCity"
              class-name="h-[251px]"
            />
          </div>
        </b-overlay>
      </div>
      <div class="lg:max-w-[360px] flex flex-wrap grow gap-x-[24px] gap-y-[16px] [&>*]:grow h-fit">
        <StripeCard
          class="lg:h-fit"
          header-class="p-0"
          body-class="px-[16px] pt-[12px] pb-[16px]"
          footer-class=""
        >
          <template #header>
            <div class="flex items-center justify-between gap-[10px] px-[16px] py-[13px] border-[#E2E2E2] border-b-[1px]">
              <b class="font-semibold text-[16px]">Status Partner</b>
              <div class="month-picker w-[130px] ms-auto border flex justify-between rounded items-center px-[5px] [&>*]:-translate-x-[8px] [&_.month-year-label]:cursor-pointer [&_.month-year-label]:py-[11px] [&_.month-year-label]:w-[128px] [&_.month-picker-wrapper]:ml-[3px] [&_.month-picker-wrapper]:!min-w-0 [&_.date-popover]:min-w-[200px] [&_.date-popover]:!-translate-x-[calc(50%-4px)] [&_.date-popover]:!translate-y-4">
                <MonthlyPicker
                  v-model="periode"
                  :month-labels="monthlabel"
                  date-format="MMM YYYY"
                  style="width: 100px"
                  input-class="border-0 w-[100px] h-full font-semibold"
                  :clear-option="false"
                  :alignment="'center'"
                  :selected-background-color="'#F95031'"
                  :max="moment()"
                  :disabled="loading.status"
                  @selected="getPartnerStatus()"
                />
                <div
                  v-if="loading.status"
                  class="min-w-[14px] h-full grid place-items-center"
                >
                  <b-spinner small />
                </div>
                <span
                  v-else
                  class="k-calendar text-3xl pointer-events-none"
                />
              </div>
            </div>
            <div class="p-[16px] pb-[12px]">
              <span class="text-[14px] font-medium">Partner Aktif</span>
              <b class="text-[20px] font-semibold text-[#34A770] mt-[12px] block">{{ dataPartnerStatus.all_time.total }}</b>
            </div>
          </template>
          <template #body>
            <div class="flex justify-between items-center">
              <span class="leading-[21px] font-medium text-[14px]">Partner Baru</span>
              <div class="flex items-center gap-[4px] text-[#34A770]">
                <b class="font-semibold text-[16px]">{{ dataPartnerStatus.active.total }}</b>
                <span
                  v-if="dataPartnerStatus.active.additional"
                  class="text-[12px]"
                >+ {{ dataPartnerStatus.active.additional }} Hari Ini</span>
              </div>
            </div>
            <div
              v-for="item, key in dataPartnerStatus.detail"
              :key="key"
              class="ml-[8px] flex items-center justify-between gap-[6px] text-[14px]"
            >
              <div class="flex items-center gap-[6px]">
                <i
                  class="min-w-[8px] h-[8px] rounded-full"
                  :class="partnerKeys[item.name]"
                />
                <span class="leading-[21px] whitespace-nowrap">{{ item.name }}</span>
              </div>
              <b class="font-semibold leading-[21px]">{{ item.total }}</b>
            </div>
          </template>
          <template #footer>
            <span class="text-[14px] font-medium">Partner Off</span>
            <div class="mt-[12px] flex items-center gap-[6px] text-[#E31A1A]">
              <b class="text-[20px] font-semibold leading-[30px]">{{ dataPartnerStatus.off.total }}</b>
              <span
                v-if="dataPartnerStatus.off.additional"
                class="text-[12px]"
              >+ {{ dataPartnerStatus.off.additional }} Hari Ini</span>
            </div>
          </template>
        </StripeCard>
        <StripeCard
          class="lg:h-fit"
          header-class=""
          body-class="px-[16px] pb-[16px] pt-[12px] min-h-full"
        >
          <template #header>
            <div class="flex items-center justify-between gap-[10px]">
              <div class="flex items-center gap-[12px]">
                <span class="text-[14px] font-medium">Customer Lifetime Value</span>
                <div
                  v-if="loading.lifetime"
                  class="w-[24px] grid place-items-center"
                >
                  <b-spinner
                    small
                  />
                </div>
                <i
                  v-else
                  v-b-tooltip.hover.top="'CLV adalah rata-rata durasi partner menunggunakan layanan Komtim'"
                  class="k-info-circle-1 text-[24px] text-[#08A0F7]"
                />
              </div>
              <div class="ms-auto selection">
                <VSelect
                  v-model="filterLifetime"
                  :reduce="e => e.value"
                  :options="optionsFilter"
                  label="text"
                  :clearable="false"
                  :disabled="loading.lifetime"
                  class="font-semibold"
                  style="width: 100px;"
                  @input="getPartnerCustomerLifetime()"
                />
              </div>
            </div>
          </template>
          <template #body>
            <div
              v-for="item, key in dataLifetime"
              :key="key"
              class="flex items-center justify-between gap-[6px] text-[14px]"
            >
              <div class="flex items-center gap-[6px]">
                <i
                  class="min-w-[8px] h-[8px] rounded-full"
                  :class="partnerKeys[item.name]"
                />
                <span class="leading-[21px] whitespace-nowrap">{{ item.name }}</span>
              </div>
              <b class="font-semibold leading-[21px]">{{ item.total }}</b>
            </div>
          </template>
        </StripeCard>
      </div>
    </div>
  </section>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import VSelect from 'vue-select'
import moment from 'moment'
import { alertError } from '@toast'
import MonthlyPicker from 'vue-monthly-picker'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import CardData from './CardData.vue'
import StripeCard from './StripeCard.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    CardData,
    VSelect,
    MonthlyPicker,
    StripeCard,
  },
  data() {
    return {
      alertError,
      isAllTime: false,
      dataGender: [],
      dataBusinessType: [],
      dataBusinessCategory: [],
      dataCity: [],
      dataLifetime: [{ name: 'Admin Marketplace', total: 0 }, { name: 'Advertiser', total: 0 }, { name: 'Customer Service', total: 0 }, { name: 'Live Streamer', total: 0 }],
      dataStatus: [],
      filterLifetime: false,
      filterCategory: false,
      optionsFilter: [
        {
          text: 'All Time',
          value: true,
        },
        {
          text: 'Aktif',
          value: false,
        },
      ],
      monthlabel: LABELMONTH,
      periode: moment(),
      dataPartnerStatus: {
        active: { total: 0, additional: null },
        all_time: { total: 0, additional: null },
        off: { total: 0, additional: null },
        detail: [{ name: 'Admin Marketplace', total: 0 }, { name: 'Advertiser', total: 0 }, { name: 'Customer Service', total: 0 }, { name: 'Live Streamer', total: 0 }],
      },
      loading: { category: false, status: false, lifetime: false },
      moment,
      partnerKeys: {
        'Admin Marketplace': 'bg-[#08A0F7]',
        Advertiser: 'bg-[#34A770]',
        'Customer Service': 'bg-[#E31A1A]',
        'Live Streamer': 'bg-[#FBA63C]',
      },
    }
  },
  mounted() {
    this.getPartnerCategory()
    this.getPartnerCustomerLifetime()
    this.getPartnerStatus()
  },
  methods: {
    async getPartnerCategory() {
      try {
        this.loading.category = true
        const params = `all_time=${this.filterCategory}`
        const url = `/v1/dashboard/partner-category?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataGender = data.find(e => e.type === 'gender')?.data.sort((a, b) => b.total - a.total)
        this.dataBusinessCategory = data.find(e => e.type === 'business_category')?.data.sort((a, b) => b.total - a.total)
        this.dataBusinessType = data.find(e => e.type === 'business_type')?.data.sort((a, b) => b.total - a.total)
        this.dataCity = data.find(e => e.type === 'city')?.data.sort((a, b) => b.total - a.total)
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Kategori Partner' })
      } finally {
        this.loading.category = false
      }
    },
    async getPartnerCustomerLifetime() {
      try {
        this.loading.lifetime = true
        const params = `all_time=${this.filterLifetime}`
        const url = `/v1/dashboard/customer-lifetime?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataLifetime = data.filter(el => Object.keys(this.partnerKeys).includes(el.name))
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Customer Lifetime' })
      } finally {
        this.loading.lifetime = false
      }
    },
    extractTotal(data, status) {
      const partner = data.find(e => e.type === 'partner').data.find(e => e.name === status)
      const additional = data.find(e => e.type === 'status').data.find(e => e.name === status)
      return {
        total: partner ? partner.total : 0,
        additional: additional ? additional.total : null,
      }
    },
    async getPartnerStatus() {
      try {
        this.loading.status = true
        const params = `date=${YEAR_MONTH(this.periode)}`
        const url = `/v1/dashboard/partner-status?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataPartnerStatus.detail = data.find(e => e.type === 'detail').data.filter(el => Object.keys(this.partnerKeys).includes(el.name))
        this.dataPartnerStatus.active = this.extractTotal(data, 'active')
        this.dataPartnerStatus.all_time = this.extractTotal(data, 'all_time')
        this.dataPartnerStatus.off = this.extractTotal(data, 'off')
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Status Partner' })
      } finally {
        this.loading.status = false
      }
    },
  },
}
</script>

<style>
.selection .vs__dropdown-toggle {
  height: 40px
}
.selection .vs__selected-options {
  text-wrap: nowrap;
}
.month-picker .month-year-display.input{
  width: 120px;
}
</style>
