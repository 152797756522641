<template>
  <div>
    <section
      v-if="!isDataDaily"
      class="bg-white p-6 rounded-[8px] flex flex-col gap-[20px]"
    >
      <div class="flex flex-col sm:flex-row gap-[10px] items-center justify-between">
        <span class="font-semibold text-[20px] text-[#333] w-full sm:!w-fit">Talent</span>
        <div class="flex flex-wrap gap-x-[24px] gap-y-[8px] items-center justify-end grow w-full sm:!w-fit">
          <div class="month-picker w-[140px] ms-auto border flex justify-between rounded items-center px-[5px] [&_.month-year-label]:py-[11px] [&_.month-year-label]:cursor-pointer [&_.month-year-label]:w-[140px] [&_.month-picker-wrapper]:-translate-x-[6px] [&_.month-picker-wrapper]:!min-w-0 [&_.date-popover]:min-w-[200px] [&_.date-popover]:!-translate-x-[calc(50%-15px)] [&_.date-popover]:!translate-y-4">
            <MonthlyPicker
              v-model="filterDate"
              :month-labels="monthlabel"
              date-format="MMM YYYY"
              style="width: 100px"
              input-class="border-0 w-[100px] h-full font-semibold"
              :clear-option="false"
              :alignment="'center'"
              :selected-background-color="'#F95031'"
              :max="moment()"
              :disabled="loading.card"
              @selected="getTalent(), getTotalTalent()"
            />
            <b-spinner
              v-if="loading.card"
              class="mr-[7px]"
              small
            />
            <span
              v-else
              class="k-calendar text-3xl"
            />
          </div>
          <div class="flex items-center gap-[8px]">
            <span class="flex items-center font-semibold whitespace-nowrap">Data Bulanan</span>
            <div class="navigate flex">
              <button
                class="flex justify-center items-center p-[8px] border rounded-left corsor-pointer bg-[#F4F4F4]"
                disabled
              >
                <span class="k-arrow-left-2" />
              </button>
              <button
                class="flex justify-center items-center p-[8px] border rounded-right corsor-pointer"
                @click="isDataDaily = true"
              >
                <span class="k-arrow-right-2 text-[#333]" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-[16px] text-[#333333]">
        <StripeCard
          v-for="(value, index) in dataTalent.filter(d => d)"
          :key="index"
          header-class=""
          body-class=""
        >
          <template #header>
            <b class="font-medium text-[14px]">{{ value.name }}</b>
            <div class="mt-[12px] flex flex-col">
              <span class="text-[10px] text-[#828282]">Talent Aktif</span>
              <strong class="text-[20px] font-semibold">{{ value.count || 0 }}</strong>
            </div>
          </template>
          <template #body>
            <div
              v-for="item, key in talentKeys"
              :key="`${index}-${key}`"
              class="flex items-center gap-[6px] text-[14px]"
            >
              <i
                v-if="item.length > 0"
                class="min-w-[8px] h-[8px] rounded-full"
                :class="item"
              />
              <span
                class="leading-[21px]"
                :class="item.length > 0 ? 'min-w-[100px]' : 'min-w-[114px]'"
              >{{ key.split('|')[0] }}</span>
              <span class="min-w-fit text-[12px]">:</span>
              <b class="min-w-[40px] font-semibold leading-[21px]">{{ value[key.split('|')[1]] || 0 }}</b>
              <span
                v-if="item.length > 0 && value[`${key.split('|')[1]}_day`] > 0"
                class="w-full text-[12px]"
                :class="key.includes('resign') ? 'text-[#E31A1A]' : 'text-[#34A770]'"
              >+ {{ value[`${key.split('|')[1]}_day`] }} Hari Ini</span>
            </div>
          </template>
        </StripeCard>
      </div>
      <div class="flex gap-[24px] flex-wrap flex-md-nowrap md:flex-nowrap">
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Empowerment'"
          :append-title-icon="'k-info-circle-1 text-2xl text-[#08A0F7]'"
          :content="`${dataTotal.empowerment || 0}`"
          :content-class="'py-[10px] text-[14px]'"
          :tooltip-text="'Total Talent Hired + Multiple pada semua layanan'"
        />
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Total Non Job'"
          :content="`${dataTotal.non_job || 0}`"
          :content-class="'py-[10px] text-[14px]'"
        />
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Total Resign'"
          :content="`${dataTotal.resign || 0}`"
          :content-class="'py-[10px] text-[14px]'"
        />
      </div>
    </section>
    <section
      v-else
      class="bg-white invoice p-6 rounded-[8px] flex flex-col gap-[24px]"
    >
      <div class="flex flex-col sm:flex-row gap-[10px] items-center justify-between relative z-[1]">
        <span class="font-semibold text-[20px] text-[#333] w-full sm:!w-fit">Talent</span>
        <div class="flex flex-wrap gap-x-[24px] gap-y-[8px] items-center justify-end grow w-full sm:!w-fit">
          <div class="selection">
            <VSelect
              v-model="filterType"
              :reduce="e => e.value"
              :options="optionsType"
              label="text"
              :clearable="false"
              class="font-semibold"
              style="width: 160px;"
            />
          </div>
          <div class="month-picker w-[140px] border flex justify-between rounded items-center px-[5px] [&_.month-year-label]:py-[11px] [&_.month-year-label]:cursor-pointer [&_.month-year-label]:w-[140px] [&_.month-picker-wrapper]:-translate-x-[6px] [&_.month-picker-wrapper]:!min-w-0 [&_.date-popover]:min-w-[200px] [&_.date-popover]:!-translate-x-[calc(50%-15px)] [&_.date-popover]:!translate-y-4">
            <MonthlyPicker
              v-model="filterDateTalentDetail"
              :month-labels="monthlabel"
              date-format="MMM YYYY"
              style="width: 100px"
              input-class="border-0 w-[100px] h-full font-semibold"
              :clear-option="false"
              :alignment="'center'"
              :selected-background-color="'#F95031'"
              :max="moment()"
              :disabled="loading.series"
              @selected="getTalentDetail()"
            />
            <b-spinner
              v-if="loading.series"
              class="mr-[7px]"
              small
            />
            <span
              v-else
              class="k-calendar text-3xl"
            />
          </div>
          <div class="flex items-center gap-[8px]">
            <span class="flex items-center font-semibold whitespace-nowrap">Data Harian</span>
            <div class="navigate flex">
              <button
                class="flex justify-center items-center p-[8px] border rounded-left corsor-pointer"
                @click="isDataDaily = false"
              >
                <span class="k-arrow-left-2 text-[#333] font-semibold" />
              </button>
              <button
                class="flex justify-center items-center p-[8px] border rounded-right corsor-pointer bg-[#F4F4F4]"
                disabled
              >
                <span class="k-arrow-right-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Chart
        :loading="loading.series"
        :chart-of="'talent'"
        :series="series"
      />
    </section>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'
import { komtimAxiosIns } from '@/libs/axios'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import moment from 'moment'
import MonthlyPicker from 'vue-monthly-picker'
import VSelect from 'vue-select'
import Chart from './Chart.vue'
import Card from './CardTotal.vue'
import StripeCard from './StripeCard.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Chart,
    Card,
    MonthlyPicker,
    VSelect,
    StripeCard,
  },
  data() {
    return {
      IDR,
      filterDate: moment(),
      filterDateTalentDetail: moment(),
      monthlabel: LABELMONTH,
      moment,
      dataTotal: {},
      dataTalent: ['Admin Marketplace', 'Advertiser', 'Customer Service', 'Live Streamer'].map(name => ({
        name, count: 0, multiple: 0, non_job: 0, new_hire: 0, resign: 0, new_partner: 0,
      })),
      talentKeys: {
        'New Hired|new_partner': 'bg-[#FBA63C]',
        'Penambahan|new_hire': 'bg-[#34A770]',
        'Pengurangan|resign': 'bg-[#E31A1A]',
        'Multiple|multiple': '',
        'Non Job|non_job': '',
      },
      isDataDaily: false,
      dataSeries: [],
      filterType: 'new_partner',
      optionsType: [
        {
          text: 'New Hired',
          value: 'new_partner',
        },
        {
          text: 'Resign',
          value: 'resign',
        },
        {
          text: 'Penambahan',
          value: 'new_hire',
        },
      ],
      loading: { card: false, series: false },
    }
  },
  computed: {
    series() {
      let result = []
      result = [
        {
          name: 'Customer Service',
          data: this.dataSeries.filter(el => el.name === 'Customer Service').map(el => el[this.filterType]),
          color: '#E31A1A',
        },
        {
          name: 'Live Streamer',
          data: this.dataSeries.filter(el => el.name === 'Live Streamer').map(el => el[this.filterType]),
          color: '#FBBC05',
        },
        {
          name: 'Advertiser',
          data: this.dataSeries.filter(el => el.name === 'Advertiser').map(el => el[this.filterType]),
          color: '#34A770',
        },
        {
          name: 'Admin Marketplace',
          data: this.dataSeries.filter(el => el.name === 'Admin Marketplace').map(el => el[this.filterType]),
          color: '#08A0F7',
        },
      ]
      return result
    },
  },
  mounted() {
    this.getTalent()
    this.getTotalTalent()
    this.getTalentDetail()
  },
  methods: {
    async getTalent() {
      try {
        this.loading.card = true
        const params = `date=${YEAR_MONTH(this.filterDate)}`
        const url = `/v1/dashboard/talent?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        const role = this.dataTalent.map(d => d.name)
        this.dataTalent = data.filter(el => role.includes(el.name))
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Talent' })
      } finally {
        this.loading.card = false
      }
    },
    async getTotalTalent() {
      try {
        this.loading.card = true
        const params = `date=${YEAR_MONTH(this.filterDate)}`
        const url = `/v1/dashboard/talent-total?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataTotal = data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Total Talent' })
      } finally {
        this.loading.card = false
      }
    },
    async getTalentDetail() {
      try {
        this.loading.series = true
        const params = `date=${YEAR_MONTH(this.filterDateTalentDetail)}`
        const url = `/v1/dashboard/talent-detail?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataSeries = data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Series Talent' })
      } finally {
        this.loading.series = false
      }
    },
  },
}
</script>

<style>
.selection .vs__dropdown-toggle {
  height: 40px
}
.selection .vs__selected-options {
  text-wrap: nowrap;
}
.month-picker .month-year-display.input{
  width: 120px;
}
</style>
