<template>
  <div class="flex flex-col rounded-[8px] h-fit border overflow-hidden text-[12px]">
    <div class="flex min-h-[42px] px-[16px] py-[12px] bg-[#F4F4F4]">
      <span class="w-7/12 flex items-center font-semibold text-[#333]">{{ tableHead }}</span>
      <span class="w-5/12 flex items-center justify-center ml-auto font-semibold text-[#333]">Jumlah</span>
    </div>
    <div
      class="flex flex-col max-h-[251px] overflow-auto"
      :class="className"
    >
      <div
        v-for="(el, index) in data"
        :key="index"
        class="flex min-h-[42px] px-[16px] py-[12px] border-b last:border-0"
      >
        <span class="w-7/12 flex items-center">{{ el.name === 'men' ? 'Laki-laki' : el.name === 'women' ? 'Perempuan' : el.name }}</span>
        <span class="w-5/12 flex items-center justify-center ml-auto">{{ el.total }} <span class="ml-[5px] text-[#34A770]">{{ ` (${Math.round((el.total / totalData) * 100)} %)` }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableHead: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalData() {
      // `this` points to the vm instance
      return this.data.reduce(((total, el) => total + el.total), 0)
    },
  },
}
</script>
