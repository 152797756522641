<template>
  <section class="flex flex-col gap-[20px] text-[#333]">
    <div class="title font-semibold text-[24px] text-[#333]">
      Dashboard
    </div>
    <Invoice />
    <Talent />
    <Partner />
  </section>
</template>

<script>
import Invoice from './Invoice.vue'
import Talent from './Talent.vue'
import Partner from './Partner.vue'

export default {
  components: {
    Invoice,
    Talent,
    Partner,
  },
}
</script>
