<template>
  <section class="bg-white p-6 rounded-[8px]">
    <div class="flex flex-col sm:flex-row items-center gap-[10px]">
      <span class="font-semibold text-[20px] text-[#333] w-full sm:!w-fit">Invoice</span>
      <div class="filter ms-auto flex justify-end items-center flex-wrap gap-[10px] w-full max-w-[396px] sm:!w-[calc(100%-100px)]">
        <VSelect
          v-model="filterIsPaid"
          :reduce="e => e.value"
          :options="optionsIsPaid"
          label="text"
          :clearable="false"
          :disabled="loading.series"
          class="font-semibold !text-[#333] grow basis-[116px]"
          @input="getChartInvoice()"
        />
        <VSelect
          v-model="filterType"
          :reduce="e => e.value"
          :options="optionsType"
          label="text"
          :clearable="false"
          :disabled="loading.series"
          class="font-semibold !text-[#333] grow basis-[158px]"
          @input="getChartInvoice()"
        />
        <YearPicker
          v-model="filterYear"
          :format="START_YEAR_NOW"
          minimum-view="year"
          name="datepicker"
          wrapper-class="border rounded cursor-pointer flex items-center grow basis-[100px] max-w-[206px]"
          calendar-class="text-center ml-[-22em] cursor-pointer"
          input-class="text-left pl-[20px] w-full h-[37px] cursor-pointer outline-none font-semibold rounded"
          :calendar-button="true"
          :disabled="loading.series"
          :calendar-button-icon="'fa-calendar'"
          :disabled-dates="disabledYears"
          @input="getChartInvoice()"
        >
          <span
            slot="afterDateInput"
            class="absolute right-[4px] pointer-events-none bottom-[1px] grid place-items-center h-full"
          >
            <b-spinner
              v-if="loading.series"
              class="mr-[7px]"
              small
            />
            <span
              v-else
              class="k-calendar text-3xl"
            />
          </span>
        </YearPicker>
      </div>
    </div>
    <Chart
      :series="series"
      :loading="loading.series"
    />
    <div class="my-[24px] flex justify-end">
      <div class="month-picker w-[130px] border flex justify-between rounded items-center px-[5px] [&_.month-year-label]:py-[11px] [&_.month-year-label]:cursor-pointer [&_.month-year-label]:w-[130px] [&_.month-picker-wrapper]:-translate-x-[6px] [&_.month-picker-wrapper]:!min-w-0 [&_.date-popover]:min-w-[200px] [&_.date-popover]:!-translate-x-[calc(50%-6px)] [&_.date-popover]:!translate-y-4">
        <MonthlyPicker
          v-model="filterMonth"
          :month-labels="monthlabel"
          date-format="MMM YYYY"
          style="width: 90px;"
          input-class="border-0 w-[100px] h-full font-semibold"
          :clear-option="false"
          :disabled="loading.total"
          :alignment="'center'"
          :selected-background-color="'#F95031'"
          :max="moment()"
          @selected="getTotalInvoice()"
        />
        <b-spinner
          v-if="loading.total"
          class="mr-[7px]"
          small
        />
        <span
          v-else
          class="k-calendar text-3xl"
        />
      </div>
    </div>
    <div class="flex gap-[24px] mt-[24px] flex-wrap flex-md-nowrap">
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Biaya Admin Paid'"
        :prepend-title-icon="'k-card-tick-1'"
        :content="`${IDR(dataTotal.admin_fee || 0)},-`"
        :content-class="'py-[10px]'"
      >
        <template v-slot:footer>
          <div class="flex flex-col">
            <span class="text-[#34A770] text-[12px]">Total partner ditagihkan : {{ dataTotal.total_partner }}</span>
            <span class="text-[#08A0F7] text-[12px]">Total talent yang ditagih invoice : {{ dataTotal.total_talent }}</span>
          </div>
        </template>
      </Card>
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Sharing Fee'"
        :prepend-title-icon="'k-convert-card'"
        :content="`${IDR(dataTotal.sharing_fee || 0)},-`"
        :content-class="'py-[10px]'"
      />
    </div>
  </section>
</template>

<script>
import { IDR } from '@/libs/currency'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import MonthlyPicker from 'vue-monthly-picker'
import moment from 'moment'
import {
  LABELMONTH, START_YEAR_NOW, YEAR, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import YearPicker from 'vuejs-datepicker'
import VSelect from 'vue-select'
import Chart from './Chart.vue'
import Card from './CardTotal.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Chart,
    Card,
    VSelect,
    YearPicker,
    MonthlyPicker,
  },
  data() {
    return {
      IDR,
      alertError,
      loading: { series: false, total: false },
      series: [],
      dataTotal: {},
      monthlabel: LABELMONTH,
      filterIsPaid: 'paid',
      filterMonth: moment(),
      optionsIsPaid: [
        {
          text: 'Paid',
          value: 'paid',
        },
        {
          text: 'Publish',
          value: 'publish',
        },
      ],
      filterType: 'admin_fee',
      optionsType: [
        {
          text: 'Biaya Admin',
          value: 'admin_fee',
        },
        {
          text: 'Sharing Fee',
          value: 'sharing_fee',
        },
      ],
      filterYear: moment().format('YYYY-MM'),
      START_YEAR_NOW,
      moment,
      disabledYears: {
        customPredictor(date) {
          if (date.getFullYear() > new Date().getFullYear()) {
            return true
          }
          return false
        },
      },
    }
  },
  mounted() {
    this.getChartInvoice()
    this.getTotalInvoice()
  },
  methods: {
    async getChartInvoice() {
      try {
        this.loading.series = true
        const params = `type=${this.filterIsPaid}&fee_type=${this.filterType || 'admin_fee'}&year=${YEAR(this.filterYear)}`
        const url = `/v1/dashboard/invoice?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        const colors = {
          'Customer Service': '#E31A1A', 'Live Streamer': '#FBBC05', Advertiser: '#34A770', 'Admin Marketplace': '#08A0F7',
        }
        this.series = Object.keys(colors).map(name => ({
          name, data: data.filter(el => el.skill === name).map(el => el.total), color: colors[name],
        }))
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Series Invoice' })
      } finally {
        this.loading.series = false
      }
    },
    async getTotalInvoice() {
      try {
        this.loading.total = true
        const params = `date=${YEAR_MONTH_DAY(moment(this.filterMonth).date(1))}`
        const url = `/v1/dashboard/total-invoice?${params}`
        const { data: { data } } = await komtimAxiosIns.get(url)
        this.dataTotal = data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Total Invoice' })
      } finally {
        this.loading.total = false
      }
    },
  },
}
</script>

<style>
.v-select.vs--single .vs__selected {
  color: #333333;
}
div.date-popover.visible {
  top: 30px;
  left: 25px !important;
}
</style>
