<template>
  <div
    class="border-[1px] rounded-[8px] border-[#E2E2E2] overflow-hidden flex flex-col grow"
  >
    <div :class="[{'p-[16px] border-[#E2E2E2] pb-[12px] border-b-[1px]': headerClass !== null}, headerClass]">
      <slot name="header" />
    </div>
    <div :class="[{'flex flex-col gap-[8px] bg-[#F8F8F8] py-[30px] px-[12px]': bodyClass !== null}, bodyClass]">
      <slot name="body" />
    </div>
    <div :class="[{'p-[16px] border-[#E2E2E2] pt-[12px] border-t-[1px]': footerClass !== null}, footerClass]">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerClass: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: String,
      default: null,
    },
    footerClass: {
      type: String,
      default: null,
    },
  },
}
</script>
