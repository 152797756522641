/* eslint-disable import/prefer-default-export */
import { IDRWithoutLbl } from '@/libs/currency'

export const optionsTalentChart = {
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    toolbar: { show: false },
    zoom: { enabled: true },
    fontFamily: 'Poppins, sans-serif',
  },
  xaxis: {
    categories: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    labels: {
      style: {
        colors: ['#333333'],
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  noData: { text: 'Tidak Ada Data' },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {
      formatter(value) {
        return `${value}`
      },
      style: {
        colors: ['#A2A3A5'],
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
}

export const optionsInvoiceChart = {
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    toolbar: { show: false },
    zoom: { enabled: true },
    fontFamily: 'Poppins, sans-serif',
  },
  xaxis: {
    categories: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    labels: {
      style: {
        colors: ['#333333'],
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  noData: { text: 'Tidak Ada Data' },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontWeight: 600,
    markers: {
      offsetY: 100,
      size: '5',
    },
    itemMargin: {
      horizontal: 5,
      vertical: 0,
    },
  },
  yaxis: {
    labels: {
      formatter(value) {
        return `${value / 1000000} Juta`
      },
      style: {
        colors: ['#A2A3A5'],
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  tooltip: {
    y: {
      formatter(value) {
        return `${IDRWithoutLbl(value)}`
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter(value) {
      return `${value > 0 ? `${(value / 1000000).toFixed(1)} Jt` : ''}`
    },
  },
}
